import { currentDateTime } from '@/utils/date'

export default {
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .leftOuterJoin(
        tables.checklist_ot,
        tables.parte_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec()
    )[0]
  },
  async firmarParte (Vue, id, formData, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    const ffirma = currentDateTime()
    await Vue.$offline.sync.insert({
      method: 'parteTrabajo.updateSync',
      params: {
        values: {
          idparte_trabajo: id,
          comentario_final_cliente: formData.comentario_final_cliente,
          ffirma,
          firma: formData.firma,
          firmante: formData.firmante,
          dni_firmante: formData.dni_firmante,
        },
      },
    })
    await Vue.$offline.db
      .update(tables.parte_trabajo)
      .set(tables.parte_trabajo.comentario_final_cliente, formData.comentario_final_cliente)
      .set(tables.parte_trabajo.firma, formData.firma)
      .set(tables.parte_trabajo.ffirma, ffirma)
      .set(tables.parte_trabajo.firmante, formData.firmante)
      .set(tables.parte_trabajo.dni_firmante, formData.dni_firmante)
      .where(tables.parte_trabajo.idparte_trabajo.eq(id))
      .exec()
    if (formData.idnivel_satisfaccion) {
      await Vue.$offline.sync.insert({
        method: 'ordenTrabajo.updateSync',
        params: {
          values: {
            idorden_trabajo: idordenTrabajo,
            idnivel_satisfaccion: formData.idnivel_satisfaccion,
          },
        },
      })
      await Vue.$offline.db
        .update(tables.orden_trabajo)
        .set(tables.orden_trabajo.idnivel_satisfaccion, formData.idnivel_satisfaccion)
        .where(tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo))
        .exec()
    }
  },
  async firmarChecklist (Vue, idchecklistOt, formData) {
    const tables = Vue.$offline.db.tables
    const ffirma = currentDateTime()
    await Vue.$offline.sync.insert({
      method: 'checklistOt.updateSync',
      params: {
        values: {
          idchecklist_ot: idchecklistOt,
          firmante: formData.firmante,
          firmante_dni: formData.dni_firmante,
          firmante_cargo: formData.firmante_cargo,
          firma: formData.firma,
          ffirma,
        },
      },
    })
    await Vue.$offline.db
      .update(tables.checklist_ot)
      .set(tables.checklist_ot.firmante, formData.firmante)
      .set(tables.checklist_ot.firmante_dni, formData.dni_firmante)
      .set(tables.checklist_ot.firmante_cargo, formData.firmante_cargo)
      .set(tables.checklist_ot.firma, formData.firma)
      .set(tables.checklist_ot.ffirma, ffirma)
      .where(tables.checklist_ot.idchecklist_ot.eq(idchecklistOt))
      .exec()
  }
}
